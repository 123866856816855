<template>
  <component :is="as" :href="href" :class="[$style.blockButton, typeClass]">
    <div :class="$style.head">
      <img :src="logo" :class="$style.logo" />
      <h2 :class="$style.title">{{ title }}</h2>
      <span :class="$style.content">{{ subtitle }}</span>
    </div>
    <div :class="$style.button"></div>
    <div :class="$style.icon">
      <slot name="icon" :className="$style.iconElement"></slot>
    </div>
    <slot></slot>
  </component>
</template>

<script>
import { computed, useCssModule } from 'vue'

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    href: {
      type: String,
    },
    as: {
      type: String,
      default: 'div'
    },
    type: {
      type: String,
      default: null,
      valiator: (value) => ['light'].indexOf(value) !== -1
    },
    logo: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const styles = useCssModule()

    return {
      typeClass: computed(() => { return props.type ? styles[`blockButton--${props.type}`] : null })
    }
  },
}
</script>


<style module>
  .blockButton {
    color: inherit;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #fff;
    border: 1px solid #f2f2f2;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    margin-bottom: 12px;
  }

  .blockButton--light {
    background-color: #f2f2f2;
    border-color: #f2f2f2;
    box-shadow: none;
  }

  .blockButton--light .icon {
    background: none;
    border: transparent;
  }

  .head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .logo {
    max-width: 40px;
    max-height: 16px;
    margin-bottom: 5px;
  }

  .title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: -0.5px;
  }

  .content {
    margin: 0;
    font-size: 13px;
    line-height: 20px;
    color: #444444;
    text-align: left;
  }

  .icon {
    width: 52px;
    height: 52px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: center;
    border-radius: 12px;
    border: solid 1px #f8f8f8;
    background-color: #fbfcfe;
    flex-shrink: 0;
  }

  .iconElement {
    width: 20px;
  }
</style>