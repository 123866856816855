<template>
  <div :class="$style.container">
    <img :class="$style.logo" src="@/assets/logo.svg" />
    <h2 :class="$style.subtitle">{{ $t('header.subtitle') }}</h2>
  </div>
</template>

<style module>
  .container {
    background: #ffffff;
    padding: 20px 0 15px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo {
    max-height: 36px;
    margin-bottom: 15px;
  }

  .subtitle {
    font-size: 12px;
    line-height: 15px;
    margin: 0;
    font-weight: normal;
  }
</style>