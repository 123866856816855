<template>
  <div :class="$style.splash">
    <img :class="$style.logo" src="@/assets/direct/logo.png" />
  </div>
</template>

<style module>
  .splash {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }

  .logo {
    max-width: 150px;
  }
</style>