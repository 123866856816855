<template>
  <div>
      <SplashScreen v-if="isLoading"></SplashScreen>
      <div v-else>
        <router-view/>
        <img :src="banner.url" />
      </div>
  </div>

</template>

<script>
import { computed, onMounted } from 'vue' 
import { useStore } from 'vuex'
import useAppConfig from '@/hooks/useAppConfig'
import SplashScreen from '@/components/SplashScreen'

export default {
  components: {
    SplashScreen,
  },
  setup() {
    const store = useStore()
    const { channelId } = useAppConfig()

    onMounted(async () => {
      if(store.state.isPristine) {
        store.dispatch('fetch', {
          channelId
        })
      }
    })
    return {
      banner: computed(() => store.state.banner),
      isLoading: computed(() => store.state.isLoading)
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600&display=swap');

body,
html {
  color: #222222;
  background: #fafafa;
  padding: 0;
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

* {
  box-sizing: border-box;
}
</style>
