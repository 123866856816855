<template>
  <div :class="$style.container">
    <div :class="$style.imageWrapper">
      <img :src="bannerSrc" :class="$style.image" />
    </div>
    <div :class="$style.content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bannerSrc: {
      type: String,
      isRequired: true,
    }
  }
}
</script>

<style module>
  .container {
    padding: 30px;
  }

  .imageWrapper {
    margin: -30px -30px -40px -30px;
    position: relative;
    padding-bottom: 90.9090%;
  }

  .image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .content {
    position: relative;
  }

</style>
