/* global AppConfig */
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import configPlugin from './plugins/AppConfig'

const messages = ['pl', 'en'].reduce((result, lang) => {
  return {
    ...result,
    [lang]: require(`@/translations/${lang}.json`)
  }
}, {})

const i18n = createI18n({
  locale: AppConfig.locale,
  fallbackLocale: 'en',
  messages: messages
})

createApp(App)
  .use(store)
  .use(i18n)
  .use(router)
  .use(configPlugin, AppConfig)
  .mount('#app')
