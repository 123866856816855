<template>
  <main>
    <TheHeader></TheHeader>
    <HomepageLayout :bannerSrc="bannerSrc">
      <InitAnimation>
        <div>
          <BlockButton
            as="a"
            href="/"
            :title="$t('homepage.buttons.shopping.title')"
            :subtitle="$t('homepage.buttons.shopping.subtitle')"
          >
            <template #icon="{ className }">
              <ExternalLinkIcon :class="className" />
            </template>
          </BlockButton>

          <router-link to="help-center" custom v-slot="{ href, navigate }">
            <BlockButton
              as="a"
              :href="href"
              :title="$t('homepage.buttons.helpcenter.title')"
              :subtitle="$t('homepage.buttons.helpcenter.subtitle')"
              @click="navigate"
            >
              <template #icon="{ className }">
                <ExternalLinkIcon :class="className" />
              </template>
            </BlockButton>
          </router-link>

          <router-link to="tracking" custom v-slot="{ href, navigate }">
            <BlockButton
              as="a"
              :href="href"
              :title="$t('homepage.buttons.tracking.title')"
              :subtitle="$t('homepage.buttons.tracking.subtitle')"
              @click="navigate"
            >
              <template #icon="{ className }">
                <NextIcon :class="className" />
              </template>
            </BlockButton>
          </router-link>

          <router-link to="returns" custom v-slot="{ href, navigate }">
            <BlockButton
              as="a"
              :href="href"
              :title="$t('homepage.buttons.returns.title')"
              :subtitle="$t('homepage.buttons.retruns.subtitle')"
              @click="navigate"
            >
              <template #icon="{ className }">
                <NextIcon :class="className" />
              </template>
            </BlockButton>
          </router-link>
        </div>
      </InitAnimation> 
    </HomepageLayout>
  </main>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import HomepageLayout from '@/components/HomepageLayout'
import TheHeader from '@/components/TheHeader'
import BlockButton from '@/components/BlockButton'
import ExternalLinkIcon from '@/assets/icons/external-link.svg'
import NextIcon from '@/assets/icons/next.svg'
import InitAnimation from '@/components/InitAnimation'

export default {
  name: 'Home',
  components: {
    InitAnimation,
    ExternalLinkIcon,
    NextIcon,
    BlockButton,
    HomepageLayout,
    TheHeader,
  },
  setup() {
    const store = useStore()

    return {
      bannerSrc: computed(() => store.state.banner.src)
    }
  }
}
</script>
