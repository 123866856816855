import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/tracking',
    name: 'tracking',
    component: () => import(/* webpackChunkName: "tracking" */ '../views/Tracking.vue')
  },
  {
    path: '/returns',
    name: 'returns',
    component: () => import(/* webpackChunkName: "returns" */ '../views/Returns.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior () {
    return { top: 0 }
  },
  routes
})

export default router
