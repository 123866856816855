import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoading: true,
    isPristine: true,
    banner: { src: null },
    returnFAQ: [],
    trackingFAQ: [],
    trackingProviders: [],
  },
  mutations: {
    fetchRequested(state) {
      state.isLoading = true
    },
    fetchSuccess(state, payload) {
      state.isPristine = false
      state.isLoading = false
      state.banner.src = payload.src
      state.returnFAQ = payload.returnFAQ
      state.trackingFAQ = payload.trackingFAQ
      state.trackingProviders = payload.trackingProviders
    }
  },
  actions: {
    async fetch({ commit }, { channelId }) {
      commit('fetchRequested')

      const response = await import(/* webpackChunkName: "[request]" */ `@/../data/${channelId}.js`)
      const { returnFAQ, trackingFAQ, trackingProviders } = response.default
      
      // @todo make request
      commit('fetchSuccess', {
        src: '/banner.png',
        returnFAQ,
        trackingFAQ,
        trackingProviders
      })
    }
  },
  modules: {
  }
})
